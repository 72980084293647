<template>
  <div class="giveaway">
    <h1 class="text-center mb-5" v-if="source === 'checkin'">
      {{ $t('giveaway.checkin.title') }}
    </h1>
    <h1 class="text-center mb-5" v-if="incentive.type === 'instant'">
      {{ incentivePrizeCTA }}
      <br />
      <strong class="text-primary">{{ incentive.prize }}.</strong>
    </h1>
    <h1 class="text-center mb-5" v-else>
      {{ incentivePrizeCTA }}
    </h1>
    <form @submit.prevent="enter">
      <div class="row">
        <div class="text-left form-group col-12 inline-block">
          <label for="phone">{{ $t('common.label.mobile_number') }}</label>
          <input
            id="phone"
            v-model="phone"
            ref="phone"
            @input="onPhoneType"
            type="tel"
            class="form-control"
            placeholder="(000) 000-0000"
            autocomplete="tel"
            :disabled="isLoading || disablePhoneInput"
          />
        </div>
      </div>

      <FadeTransition>
        <div v-show="checkedPhone">
          <div class="row">
            <div class="text-left form-group col-12 inline-block">
              <label for="name">{{ $t('common.label.full_name') }}</label>
              <input
                id="name"
                ref="name"
                v-model="name"
                type="text"
                class="form-control"
                placeholder="John doe"
                autocomplete="name"
              />
            </div>
          </div>

          <div class="row">
            <div class="text-left form-group col-12 inline-block">
              <label for="email">
                {{ $t('common.label.email_address') }}
                <span class="text-muted">
                  {{
                    location.requireEmails
                      ? $t('common.single_word.required')
                      : $t('common.single_word.optional')
                  }}
                </span>
              </label>
              <input
                id="email"
                v-model="email"
                type="email"
                class="form-control"
                placeholder="name@address.com"
                autocomplete="email"
              />
            </div>
          </div>

          <!-- <div
            class="text-left mb-4"
            style="display: flex; align-items: flex-start"
          >
            <input
              type="checkbox"
              id="agreeCheckbox"
              v-model="legalAknowledged"
              style="margin-right: 8px; margin-top: 0"
              v-show="showLegalAcknowledged"
            />
            <label for="agreeCheckbox" style="margin: 0">
              <small class="tight-text">{{ legalText }}</small>
            </label>
          </div> -->
          <div
            class="checkbox-wrapper"
            style="display: flex; align-items: center"
            v-if="showLegalAcknowledged"
          >
            <div
              @click="legalAcknowledged = !legalAcknowledged"
              class="custom-checkbox"
              :class="{ checked: legalAcknowledged }"
            >
              <span class="checkmark" v-if="legalAcknowledged">✔</span>
            </div>
            <label
              @click="legalAcknowledged = !legalAcknowledged"
              for="agreeCheckbox"
              class="legal-text"
            >
              <small class="tight-text">{{ legalText }}</small>
            </label>
          </div>
          <div
            v-else
            class="text-left mb-4"
            style="display: flex; align-items: flex-start"
          >
            <small class="tight-text">{{ legalText }}</small>
          </div>

          <div class="row">
            <div class="col-12">
              <div v-if="errMessage" class="alert alert-danger" role="alert">
                {{ errMessage }}
              </div>
              <button
                type="submit"
                class="btn btn-lg btn-block btn-primary mb-3 text-capitalize"
                :class="{ 'is-loading': isLoading }"
                :disabled="!isFormValid"
              >
                {{ $t('giveaway.button.submit') }}
              </button>
            </div>
          </div>
        </div>
      </FadeTransition>
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import CustomerService from '@/services/CustomerService'
import FadeTransition from '@/components/Transitions/FadeTransition'
import { createNamespacedHelpers } from 'vuex'
const PromoModule = createNamespacedHelpers('promo')
const CustomerModule = createNamespacedHelpers('CustomerModule')
const I18nModule = createNamespacedHelpers('i18n')

export default {
  name: 'Giveaway',
  components: {
    FadeTransition,
  },
  data: () => ({
    name: '',
    phone: '',
    email: '',
    isLoading: false,
    checkedPhone: false,
    disablePhoneInput: false,
    errMessage: '',
    legalAcknowledged: false,
  }),
  computed: {
    ...mapState([
      'location',
      'leftFeedback',
      'mixpanelPayload',
      'customer',
      'source',
    ]),
    ...I18nModule.mapGetters(['selectLoadedLanguage']),
    ...mapGetters(['incentive', 'selectCompany']),
    isGrubhub() {
      try {
        return this.source?.toLowerCase() === 'grubhub' || localStorage.getItem('marketingOptIn') === 'true'
      } catch (error) {
        console.log(error)
        return false
      }
    },
    showLegalAcknowledged() {
      return [
        '5d02afeee2e45649bb4b56fb',
        '65ce6fe5e8e3269a0074d43f',
        '65cf8b41488b534b1c9644c8',
      ].includes(this.selectCompany?._id)
    },
    legalText() {
      if (this.isGrubhub) {
        return this.$t('giveaway.legal_text.grubhub', [
          this.$t('giveaway.button.submit'),
          this.selectCompany.name,
        ])
      } else {
        return this.$t('giveaway.legal_text.default', [
          this.$t('giveaway.button.submit'),
          this.selectCompany.name,
        ])
      }
    },
    isFormValid() {
      const isPhoneValid = this.validatePhone(this.phone)
      if (this.isLoading || !isPhoneValid) {
        return false
      }

      if (this.location.requireEmails && !this.email) {
        return false
      }

      if (this.email && !this.validateEmail(this.email)) {
        return false
      }

      if (this.showLegalAcknowledged && !this.legalAcknowledged) {
        return false
      }

      return true
    },
    incentivePrizeCTA() {
      switch (this.incentive.type) {
        case 'instant':
          return this.$t('giveaway.incentive.call_to_action.instant')
        case 'sweepstakes':
        default:
          return this.$t('giveaway.incentive.call_to_action.default')
      }
    },
  },
  async mounted() {
    this.$mixpanel.track('Enter Giveaway Prompt', {
      ...this.mixpanelPayload,
    })

    if (this.customer && this.customer.phone) {
      this.phone = this.formatPhone(this.customer.phone)
      this.disablePhoneInput = true
      this.onPhoneType()
    } else {
      const query = this.$route.query
      this.phone = query.customerPhone
        ? this.formatPhone(query.customerPhone)
        : ''
      await this.onPhoneType()
      this.name = query.customerName || ''
      this.email = query.customerEmail || ''
    }
  },
  methods: {
    ...mapMutations(['setCustomerId']),
    ...PromoModule.mapActions(['sendIncentive']),
    ...CustomerModule.mapActions(['setCustomer']),
    toggleLegalAcknowledged() {
      this.legalAcknowledged = !this.legalAcknowledged
    },
    formatPhone(phone) {
      phone = phone.replace('+', '').replace(/\D+/g, '')
      if (phone.charAt(0) === '1') {
        phone = phone.substr(1)
      }
      return `(${phone.substring(0, 3)}) ${phone.substring(
        3,
        6,
      )}-${phone.substring(6)}`
    },

    validateEmail(email) {
      const emailRegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,8})+$/
      return emailRegExp.test(email.toLowerCase())
    },

    validatePhone(phone) {
      const stripped = (phone || '').replace(/\D/g, '')
      return /^\d{10}$/g.test(stripped)
    },

    async onPhoneType() {
      if (this.phone.length > 9) {
        this.phone = this.formatPhone(this.phone)
      }

      if (this.phone.length === 14) {
        try {
          const response = await CustomerService.getByPhone({
            phone: this.phone,
          })
          const { customer } = response.body

          if (customer.phone) {
            this.$mixpanel.track('Matched Existing Customer', {
              ...this.mixpanelPayload,
              phone: this.phone,
            })
            this.setCustomer(customer)
          }

          this.email = customer.email
          this.name = customer.name
        } catch (err) {
          console.error(err)
        } finally {
          this.checkedPhone = true

          if (!this.name) {
            this.$refs.name.focus()
          } else if (this.$refs.phone) {
            this.$refs.phone.blur()
          }
        }
      }
    },
    async enter() {
      const { sid: surveyId, prev } = this.$route.query
      const { _id: locationId, company } = this.location

      this.isLoading = true
      this.errMessage = undefined

      this.$mixpanel.track('Created Customer', {
        ...this.mixpanelPayload,
      })

      try {
        const response = await CustomerService.create({
          surveyId,
          locationId,
          name: this.name,
          phone: this.phone,
          email: this.email,
          companyId: company._id,
          grubhubNoOptIn: this.isGrubhub ? true : false,
          presumedLanguage: this.selectLoadedLanguage,
        })

        const { customerId } = response.body
        this.setCustomerId(customerId)

        // check if incentive type is instant, and send promo to customer
        if (this.incentive && this.incentive.type === 'instant') {
          const payload = {
            customerId: customerId,
            locationId: locationId,
            incentiveId: this.incentive._id,
          }
          await this.sendIncentive(payload) // send incentive to customer
        }

        if (prev === 'feedback') {
          this.$router.push({
            name: 'entered',
            params: { locationId },
            query: {
              prev,
              source: this.source,
              language: this.$route.query.language,
            },
          })
        } else {
          this.$router.push({
            name: 'leave-review',
            params: { locationId, customerId },
            query: { language: this.$route.query.language },
          })
        }
      } catch (err) {
        this.errMessage = err.body.message
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.giveaway {
  max-width: 300px;
  margin: auto;
}
.tight-text {
  display: block;
  line-height: 120% !important;
  font-size: 11px !important;
}
.checkbox-wrapper {
  display: flex;
  position: relative; /* Establishes a positioning context */
  margin-bottom: 16px; /* Adjust based on your design */
}

.custom-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 15px; /* Checkbox size */
  height: 15px; /* Checkbox size */
  border: 2px solid #757575; /* Border color */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  cursor: pointer;
}

.checked {
  background-color: #ddd; /* Custom color for checked state */
}

.checkmark {
  display: block;
  width: 100%; /* Ensures the span fills the checkbox for horizontal centering */
  text-align: center; /* Center the checkmark text horizontally */
  line-height: 18px; /* Adjust this based on the actual height of your checkbox to center vertically */
  font-size: 11px; /* Adjust if necessary, ensuring it fits within the checkbox */
}

.legal-text {
  margin-left: 20px; /* Space for the checkbox, adjust as needed */
  cursor: pointer;
  text-align: left; /* Ensure the text is aligned left */
}
</style>
